import Accordion from "react-bootstrap/Accordion";
import BillingDetails from "./BillingDetails";
import BasicDetails from "./BasicDetails";
import ShippingDetails from "./ShippingDetails";
import ItemsDetail from "./ItemsDetail";
import "../../StoreDepartment/DeliveryChallan/deliveryChallan.css";
import { useFormik } from "formik";
import { getBranchName, getVendorDropDown } from "../../Purchase/purchaseApi";
import { useEffect, useState } from "react";
import { addDeliveryChallanApi } from "../commonComponents/storeApi";
import { errorsMessage } from "../../constant";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deliveryChallanItemsValidation,
  DeliveryChallanSchema,
} from "./DeliveryChallanSchema";

function AddDeliveryChallan() {
  const user = JSON.parse(localStorage.getItem("profile"));
  const user_id = user?.response?.userDetails?.id;
  const [multipleItemDetail, setMultipleItemDetail] = useState([]);
  const [stateDropdown, setStateDropdown] = useState([]);
  const [itemIndex, setItemIndex] = useState("");
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [itemsValidations, setItemValidation] = useState({});
  const [serverSideValidation, setServerSideValidation] = useState("");
  const [branchList, setBranchList] = useState([]);
  const { state } = useLocation();
  const deliveryChallanType = state;

  const itemInitialValue = {
    item_name: "",
    hsn_number: "",
    unit: "",
    qty: "",
    rate: "",
    amount: "",
    gst_type: "",
    gst_rate: "",
    total_amount: "",
    item_id: "",
    item_code: "",
    gst_amount: "",
  };
  const [itemsValue, setItemValues] = useState({
    ...itemInitialValue,
  });

  const initialValue = {
    branch_id: "",
    transport_mode: "",
    vehicle_number: "",
    date_of_supply: "",
    store_id: "",
    insured_policy_number: "",
    purchase_order_ref_no: "",
    emay_bill: "",
    billing_party_id: "",
    billing_gstin: "",
    billing_address: "",
    billing_state_id: "",
    billing_pincode: "",
    shipping_party_id: "",
    shipping_gstin: "",
    shipping_address: "",
    shipping_state_id: "",
    shipping_pincode: "",
    item_details: [{ ...itemInitialValue }],
    branch_type: "For Branch",
  };

  function getDropDown() {
    getVendorDropDown({ user_id })
      .then((res) => {
        setStateDropdown(
          res?.data?.response?.PartyMasterDropdownList?.state_masters
        );
      })
      .catch((err) => {
        if (err) {
          errorsMessage(err?.response?.status);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  }

  function getBranchDropdown() {
    getBranchName({ user_id })
      .then((res) => {
        setBranchList(res?.data?.response?.BranchMasterList);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      });
  }

  function handleItemChange(event) {
    const { name, value } = event.target;

    setItemValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleAddDetails() {
    const { error, DeliveryChallanitems } =
      deliveryChallanItemsValidation(itemsValue);
    setItemValidation(DeliveryChallanitems);
    if (!error) {
      if (!Number.isInteger(itemIndex)) {
        const data = [...multipleItemDetail];
        data.push(itemsValue);
        setMultipleItemDetail(data);
        setItemValues({ ...itemInitialValue });
        setFieldValue("item_details", data);
        setItemIndex("");
      } else {
        const data = [...multipleItemDetail];
        data[itemIndex] = itemsValue;
        setMultipleItemDetail(data);
        setItemValues({ ...itemInitialValue });
        setFieldValue("item_details", data);
        setItemIndex("");
      }
    }
  }

  function handleDeleteItem(i) {
    const updatedItems = multipleItemDetail.filter((_, index) => index !== i);

    setMultipleItemDetail(updatedItems);

    setFieldValue("item_details", updatedItems);
  }
  function handleEditItem(i) {
    const updateItem = multipleItemDetail[i];
    setItemValues(updateItem);
    setItemIndex(i);
  }

  useEffect(() => {
    getBranchDropdown();
    getDropDown();
  }, []);

  function addDeliveryChallan(values) {
    const data = {
      user_id,
      ...values,
      type: deliveryChallanType,
    };
    setSpinner(true);
    addDeliveryChallanApi(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Delivery Challan Added successfully");
          navigate(-1);
        }
      })
      .catch((error) => {
        if (error?.response?.status == 422) {
          setServerSideValidation(error?.response?.data?.response);
        }
        errorsMessage(error?.response?.status);
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: DeliveryChallanSchema,
    onSubmit: (values) => {
      addDeliveryChallan(values);
    },
  });
  console.log("values", values);
  return (
    <form>
      <div className="page-wrapper">
        <div className="page-content-tab responsive-tab">
          <div className="container-fluid mt-4">
            <h4 className="ms-3">Add Delivery Challan</h4>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="custom-accordion-header">
                  <h5>Basic Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <BasicDetails
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    serverSideValidation={serverSideValidation}
                    deliveryChallanType={deliveryChallanType}
                    branchList={branchList}
                    branch_type={values?.branch_type}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="custom-accordion-header">
                  <h5>Billing Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <BillingDetails
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    stateDropdown={stateDropdown}
                    errors={errors}
                    touched={touched}
                    serverSideValidation={serverSideValidation}
                    deliveryChallanType={deliveryChallanType}
                    branchList={branchList}
                    branch_type={values?.branch_type}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="custom-accordion-header">
                  <h5>Shipping Details</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <ShippingDetails
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    stateDropdown={stateDropdown}
                    errors={errors}
                    touched={touched}
                    serverSideValidation={serverSideValidation}
                    deliveryChallanType={deliveryChallanType}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="custom-accordion-header">
                  <h5>Items</h5>
                </Accordion.Header>
                <Accordion.Body>
                  <ItemsDetail
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    itemsValue={itemsValue}
                    handleItemChange={handleItemChange}
                    handleAddDetails={handleAddDetails}
                    multipleItemDetail={multipleItemDetail}
                    setItemValues={setItemValues}
                    handleDeleteItem={handleDeleteItem}
                    handleEditItem={handleEditItem}
                    itemIndex={itemIndex}
                    itemsValidations={itemsValidations}
                    deliveryChallanType={deliveryChallanType}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="text-end mt-3">
              <button
                className="btn btn-danger mx-2"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>

              <button className="btn btn-danger" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddDeliveryChallan;
