import { useEffect, useState } from "react";

import "./App.css";

import "typeface-poppins";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Login from "./Login/Login";
import { setUser } from "./redux/features/authSlice";
import PrivateRoute from "./Components/PrivateRoute";
import ForgotPassword from "./Login/ForgotPassword";
import AnalyticsIndex from "./Pages/AnalyticsIndex";
import { useDispatch } from "react-redux";
import VerifyOtp from "./Login/VerifyOtp";
import ChangePassword from "./Login/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileAdmin from "./Pages/ProfileAdmin";
import ProfileUser from "./Pages/ProfileUser";
import ProjectsKanbanBoard from "./Pages/ProjectsKanbanBoard";
import ProjectsProject from "./Pages/ProjectsProject";
import ProjectsProjectDetail from "./Pages/ProjectsProjectDetail";
import ProjectsProjectDetailPdf from "./Pages/ProjectsProjectDetailPdf";
import ProjectsDepart from "./Pages/ProjectsDepart";
import ProjectsDepartAdd from "./Pages/ProjectsDepartAdd";
import EditDepartment from "./Pages/EditForms/EditDepartment";
// import ProjectDepartAddNew from './Pages/ProjectDepartAddNew'
import ProjectsUsers from "./Pages/ProjectsUsers";
import ProjectsUsersAdd from "./Pages/ProjectsUsersAdd";
import EditUserForm from "./Pages/EditUserForm";
import DownloadProjectPdf from "./Pages/DownloadProjectPdf";
import RailwayZone from "./Pages/RailwayZone";
import RailwayZoneAdd from "./Pages/AddForms/RailwayZoneAdd";
import EditRailwayZone from "./Pages/EditForms/EditRailwayZone";
import EmdPaidStatus from "./Pages/EmdPaidStatus";
import EmdPaidStatusAdd from "./Pages/AddForms/EmdPaidStatusAdd";
import EditEmdPaidStatus from "./Pages/EditForms/EditEmdPaidStatus";
import SdPaidStatus from "./Pages/SdPaidStatus";
import SdPaidStatusAdd from "./Pages/AddForms/SdPaidStatusAdd";
import EditSdPaidStatus from "./Pages/EditForms/EditSdPaidStatus";
import SdPaidThrough from "./Pages/SdPaidThrough";
import SdPaidThroughAdd from "./Pages/AddForms/SdPaidThroughAdd";
import EditSdPaidThrough from "./Pages/EditForms/EditSdPaidThrough";
import PgPaidStatus from "./Pages/PgPaidStatus";
import PgPaidStatusAdd from "./Pages/AddForms/PgPaidStatusAdd";
import EditPgPaidStatus from "./Pages/EditForms/EditPgPaidStatus";
import PgPaidThrough from "./Pages/PgPaidThrough";
import PgPaidThroughAdd from "./Pages/AddForms/PgPaidThroughAdd";
import EditPgPaidThrough from "./Pages/EditForms/EditPgPaidThrough";
import InspectionAgency from "./Pages/InspectionAgency";
import InspectionAgencyAdd from "./Pages/AddForms/InspectionAgencyAdd";
import EditInspectionAgency from "./Pages/EditForms/EditInspectionAgency";
import MaterialAccepted from "./Pages/MaterialAccepted";
import MaterialAcceptedAdd from "./Pages/AddForms/MaterialAcceptedAdd";
import EditMaterialAccepted from "./Pages/EditForms/EditMaterialAccepted";
import MaterialRejected from "./Pages/MaterialRejected";
import MaterialRejectedAdd from "./Pages/AddForms/MaterialRejectedAdd";
import EditMaterialRejected from "./Pages/EditForms/EditMaterialRejected";
import PaymentStatus from "./Pages/PaymentStatus";
import PaymentStatusAdd from "./Pages/AddForms/PaymentStatusAdd";
import EditPaymentStatus from "./Pages/EditForms/EditPaymentStatus";
import RitesIcStatus from "./Pages/RitesIcStatus";
import RitesIcStatusAdd from "./Pages/AddForms/RitesIcStatusAdd";
import EditRitesIcStatus from "./Pages/EditForms/EditRitesIcStatus";
import DownloadPdf from "./Pages/DownloadPdf";
import DepartmentListDetail from "./Pages/DepartmentListDetail";
import Unit from "./Pages/Unit";
import UnitAdd from "./Pages/AddForms/UnitAdd";
import EditUnit from "./Pages/EditForms/EditUnit";
import ProjectType from "./Pages/ProjectType";
import ProjectTypeAdd from "./Pages/AddForms/ProjectTypeAdd";
import EditProjectType from "./Pages/EditForms/EditProjectType";
import Designation from "./Pages/Designation";
import DesignationAdd from "./Pages/AddForms/DesignationAdd";
import EditDesignation from "./Pages/EditForms/EditDesignation";
import WorkStatus from "./Pages/WorkStatus";
import EditWorkStatus from "./Pages/EditForms/EditWorkStatus";
import WorkStatusAdd from "./Pages/AddForms/WorkStatusAdd";
import ClientCategory from "./Pages/ClientCategory";
import EditClientcategory from "./Pages/EditForms/EditClientCategory";
import ClientCategoryAdd from "./Pages/AddForms/ClientCategoryAdd";
import TicketType from "./Pages/TicketType";
import TicketTypeAdd from "./Pages/AddForms/TicketTypeAdd";
import EditTicketType from "./Pages/EditForms/EditTicketType";
import UsersRoles from "./Pages/UsersRoles";
import AssignPermission from "./Pages/AssignPermission";
import PermissionList from "./Pages/PermissionList";
import FeaturesList from "./Pages/FeaturesList";
import SubDivison from "./Pages/SubDivison";
import EditSubDivison from "./Pages/EditForms/EditSubDivison";
import SubDivisonAdd from "./Pages/AddForms/SubDivisionAdd";
import PurchaseMaterialCosting from "./Components/PurchaseMaterialCosting";
import Aside from "./Components/Aside";
import MaterialCostingItem from "./Pages/MaterialCostingItem";
import FinancialTarget from "./Pages/FinancialTarget";
import NewProjectAdd from "./Pages/NewProjectAdd";
import BoardGroup from "./Pages/BoardGroup";
import EditProjectData from "./Pages/EditForms/EditProjectData";
import { useSelector } from "react-redux";
import GroupStatus from "./Pages/GroupStatus";
import ProjectCategory from "./Pages/ProjectCategory";
import AddProjectCategory from "./Pages/AddForms/AddProjectCategory";
import EditProjectCategory from "./Pages/EditForms/EditProjectCategory";
import TenderAddPage from "./Tender/Pages/TenderAddPage.js";
import Home from "./Components/Home";
import TenderEditPage from "./Tender/Pages/TenderEditPage.js";
import Responsibility from "./Pages/Responsibility";
import ManageRole from "./Pages/ManageRole";
import TenderDashboard from "./Tender/Pages/TenderDashboard.js";
import TenderCards from "./Tender/TenderComponents/TenderCards";
import TenderKanban from "./Tender/Pages/TenderKanban.js";
import TenderView from "./Tender/Pages/TenderListPage.js";
import { fetchDropdown } from "./redux/features/allDropdownSlice.js";
import TenderDetails from "./Tender/TenderComponents/TenderDetails.js";
import EmdReturnTracker from "./Tender/Pages/EmdReturnTracker.js";
import PurchaseDashboard from "./Purchase/PurchaseComponents/PurchaseDashboard.js";
import PurchaseProjectReview from "./Purchase/PurchaseComponents/PurchaseProjectReview.js";
import PurchaseProjectDetail from "./Purchase/PurchaseComponents/PurchaseProjectDetail.js";
import TenderPrivateRoute from "./Tender/TenderPrivateRoute.js";
import EmdTrashBin from "./Tender/Pages/EmdTrashBin.js";
import VendorListPage from "./Purchase/SettingPage/VendorListPage.js";
import MainGroupMasterList from "./Purchase/SettingPage/MainGroupMasterList.js";
import SubGroupMasterList from "./Purchase/SettingPage/SubGroupMasterList.js";
import ItemMasterList from "./Purchase/SettingPage/ItemMaster/ItemMasterList.js";
import StateMasterList from "./Purchase/SettingPage/StateMasterList.js";
import CountryMasterList from "./Purchase/SettingPage/CountryMasterList.js";
import CityMasterList from "./Purchase/SettingPage/CityMasterList.js";
import UnitMasterList from "./Purchase/SettingPage/UnitMasterList.js";
import TermsAndConditionsList from "./Purchase/SettingPage/TermsAndConditionsList.js";
import PrintoutTypeMasterList from "./Purchase/SettingPage/PrintoutTypeMasterList.js";
import AddParameterList from "./Purchase/SettingPage/AddParameterList.js";
import GodownMasterList from "./Purchase/SettingPage/GodownMasterList.js";
import PartyWiseItemList from "./Purchase/SettingPage/PartyWiseItemList.js";
import UploadTenderDocumentList from "./Purchase/SettingPage/UploadTenderDocumentList.js";
import TenderDetailsList from "./Purchase/SettingPage/TenderDetailsList.js";
import ItemDetailsList from "./Purchase/SettingPage/ItemDetailsList.js";
import ApprovalMasterList from "./Purchase/SettingPage/ApprovalMasterList.js";
import VendorwiseItemList from "./Purchase/SettingPage/VendorwiseItemList.js";
import PartywiseRateExcelFormatList from "./Purchase/SettingPage/PartywiseRateExcelFormatList.js";
import VechileMasterList from "./Purchase/SettingPage/VechileMasterList.js";
import PurchasePrivateRoute from "./Purchase/PurchasePrivateRoutes.js";
import PurchaseOrder from "./Purchase/ManagmentPages/PurchaseOrder/PurchaseOrder.js";
import ItemMaster from "./Purchase/SettingPage/ItemMaster/ItemMaster.js";
import BrandMasterList from "./Purchase/SettingPage/BrandMasterList.js";
import MaterialMasterList from "./Purchase/SettingPage/MaterialMasterList.js";
import EditItemMaster from "./Purchase/SettingPage/ItemMaster/EditItemMaster.js";
import AddVendorFormPage from "./Purchase/PurchaseComponents/VendorForms/AddVendorFormPage.js";
import EditVendorFormPage from "./Purchase/PurchaseComponents/VendorForms/EditVendorFormPage.js";
import AddWarrantyTracker from "./Backoffice/BackofficeComponents/Trackers/WarrantyTracker/AddWarrantyTracker.js";
import WarrantyTrackerTable from "./Backoffice/BackofficeComponents/Trackers/WarrantyTracker/WarrantyTrackerTable.js";
import WarrantyTracker from "./Backoffice/BackofficeComponents/Trackers/WarrantyTracker/WarrantyTracker.js";
import EditWarrantyTracker from "./Backoffice/BackofficeComponents/Trackers/WarrantyTracker/EditWarrantyTracker.js";
import DefectTypes from "./Pages/DefectTypes.js";
import AddCoachProduction from "./Backoffice/BackofficeComponents/Trackers/CoachProduction/AddCoachProduction.js";
import POListingPage from "./Purchase/ManagmentPages/POListingPage.js";
import EditCoachProduction from "./Backoffice/BackofficeComponents/Trackers/CoachProduction/EditCoachProduction.js";
import AddLogisticForm from "./StoreDepartment/Logistics/AddLogistic.js";
import LogisticList from "./StoreDepartment/Logistics/LogisticList.js";
import EditLogisticForm from "./StoreDepartment/Logistics/EditLogistic.js";
import AcceptVehicleRequest from "./StoreDepartment/Logistics/AcceptVehicleRequest.js";
import AddVechileRequest from "./StoreDepartment/Logistics/AddVechileRequest.js";
import VehicleRequestListing from "./StoreDepartment/Logistics/VehicleRequestListing.js";
import EditVehicleRequest from "./StoreDepartment/Logistics/EditVehicleRequest.js";
import RequestDetail from "./StoreDepartment/Logistics/RequestDetail.js";
import StoreDashboard from "./StoreDepartment/StoreDashboard.js";
import PurchaseOrderPdf from "./Purchase/ManagmentPages/PurchaseOrder/PurchaseOrderPdf.js";
import MatarialList from "./StoreDepartment/Material/MaterialList.js";
import MaterialDetail from "./StoreDepartment/Material/MaterialDetail.js";
import InventoryList from "./StoreDepartment/Material/InventoryList.js";
import AddInventoryForm from "./StoreDepartment/Material/AddInventoryForm.js";
import StoreAsidebar from "./StoreDepartment/StoreAsidebar.js";
import KanbanBoard from "./Components/ProjectKanbanBoard.js";
import KanbanBoardProject from "./Components/ProjectKanban/KanbanBoard.js";
import KanbanCreateProject from "./Components/ProjectKanban/KanbanCreateProject.js";
import AddRequisitionForm from "./StoreDepartment/Material/AddRequisitionForm.js";
import EditDeliveryChallan from "./StoreDepartment/Material/EditDeliveryChallan.js";
import QMSList from "./StoreDepartment/QMS/QMSList.js";
import StorePrivateRoute from "./StoreDepartment/StorePrivateRoute.js";
import EditRequisitionForm from "./StoreDepartment/Material/EditRequistionForm.js";
import AllStore from "./StoreDepartment/AllStore.js";
import UpdatePurchaseOrder from "./Purchase/ManagmentPages/PurchaseOrder/UpdatePurchaseOrder.js";
import OrganistaionDashboard from "./HrDepartment/Organisation/OrganisationDashboard.js";
import EmployeeDirectory from "./HrDepartment/Employees/EmployeeDirectory.js";
import AddEmployee from "./HrDepartment/Organisation/AddEmployee.js";
import EmployeeDetail from "./HrDepartment/Organisation/EmployeeDetail/EmployeeDetail.js";
import CurrencyMaster from "./Purchase/SettingPage/CurrencyMaster.js";
import LeaveDashboard from "./HrDepartment/LeaveDashboard/LeaveDashboard/LeaveDashboard.js";
import EmployeeAttendance from "./HrDepartment/LeaveDashboard/EmployeeAttendance";
// import LeaveDashboard from "./LeaveDashboard/LeaveDashboard/LeaveDashboard.js";
// import EmployeeAttendance from "./LeaveDashboard/EmployeeAttendance.js";
import OrganisationStructure from "./HrDepartment/Organisation/organisationStructure/OrganisationStructure.js";
import GoodReceiptNote from "./StoreDepartment/GRNComponents/GoodReceiptNote.js";
import AddGoodsReceipt from "./StoreDepartment/GRNComponents/AddGoodsReceipt.js";
import EditGoodReceiptNote from "./StoreDepartment/GRNComponents/EditGoodReceiptNote.js";
import AddDeliveryChallan from "./StoreDepartment/DeliveryChallan/AddDeliveryChallan.js";
import DeliveryChallanList from "./StoreDepartment/DeliveryChallan/DeliveryChallanList.js";
import UpdateDeliveryChallan from "./StoreDepartment/DeliveryChallan/UpdateDeliveryChallan.js";
import InventoryTable from "./StoreDepartment/Inventory/InventoryTable.js";
import Inventory from "./StoreDepartment/Inventory/Inventory.js";
import NonTenderPurchaseOrderParent from "./Purchase/PurchaseComponents/NonTenderPurchaseOrderParent.js";
import UpdateNonTenderPurchaseOrder from "./Purchase/ManagmentPages/PurchaseOrder/UpdateNonTenderPurchaseOrder.js";
import InventoryDetailPage from "./StoreDepartment/Inventory/InventoryDetailPage.js";
import EmployeeMainTab from "./HrDepartment/Employees/EmployeeMainTab.js";
import ExitsMain from "./HrDepartment/Organisation/OrganisationExits/ExitMainTab.js";
import TimeDashboard from "./HrDepartment/Time/TimeDashboard.js";
import RequisitionList from "./StoreDepartment/RequisitonComponents/RequistionList.js";
import ViewLotsLogs from "./Components/ProjectForms/EditForms/ViewLotsLogs.js";
import HrPrivateRoute from "./HrDepartment/HrPrivateRoute.js";
import AddInvoice from "./StoreDepartment/invoice/AddInvoice.js";
import InvoiceList from "./StoreDepartment/invoice/InvoiceList.js";
import UpdateInvoice from "./StoreDepartment/invoice/UpdateInvoice.js";
import AddCustomer from "./StoreDepartment/customerComponents/AddCustomer.js";
import CustomerList from "./StoreDepartment/customerComponents/CustomerList.js";
import UpdateCustomer from "./StoreDepartment/customerComponents/UpdateCustomer.js";
import DateRangePickerLatest from "./StoreDepartment/commonComponents/DateRangePicker.js";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state.auth })); //Important;
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const getToken = userProfile?.response?.token;
  const bearer_token = `Bearer ${getToken}`;
  const config = {
    headers: {
      Authorization: bearer_token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  useEffect(() => {
    if (user_id) {
      dispatch(fetchDropdown({ user_id, config }));
    }
  }, [user_id, dispatch]);
  useEffect(() => {
    dispatch(setUser(userProfile));
  }, []);
  if (isLoading) {
    //
    return null;
  }
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          {/* auth routes start */}
          <Route exact path="/" element={<Home></Home>}></Route>
          <Route exact path="/login" element={<Login />}></Route>

          <Route exact path="*" element={<Home />}></Route>
          <Route
            exact
            path={"/ForgotPassword"}
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/VerifyOtp" element={<VerifyOtp />}></Route>

          <Route
            exact
            path="/ChangePassword"
            element={<ChangePassword />}
          ></Route>

          {/* auth routes end */}
          {/* protected routes start */}
          <Route
            exact
            path="/Dashboard"
            element={
              <PrivateRoute>
                <Aside>
                  <Dashboard />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/AnalyticsIndex"
            element={
              <PrivateRoute>
                <Aside>
                  <AnalyticsIndex />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user-profile/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <ProfileAdmin />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ProfileUser"
            element={
              <PrivateRoute>
                <Aside>
                  <ProfileUser />
                </Aside>
              </PrivateRoute>
            }
          />
          {/* project routes start */}

          <Route
            exact
            path="/ProjectsKanbanBoard"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsKanbanBoard />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/projects"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsProject />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="projects/projectdetail/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsProjectDetail />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="projects/projectpreview/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsProjectDetailPdf />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/department"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsDepart />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/department/add-department"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsDepartAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/department/edit-department/:id"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditDepartment />{" "}
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/ProjectsUsers"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsUsers />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ProjectsUsers/ProjectsUsersAdd"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectsUsersAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ProjectsUsers/edit-user/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <EditUserForm />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/DownloadProjectPdf/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <DownloadProjectPdf />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/railway-zone"
            element={
              <PrivateRoute>
                <Aside>
                  <RailwayZone />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-railway-zone"
            element={
              <PrivateRoute>
                <Aside>
                  <EditRailwayZone />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/railway-zone-add"
            element={
              <PrivateRoute>
                <Aside>
                  <RailwayZoneAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/emd-paid"
            element={
              <PrivateRoute>
                <Aside>
                  <EmdPaidStatus />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-emd-paid"
            element={
              <PrivateRoute>
                <Aside>
                  <EditEmdPaidStatus />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/emd-paid-add"
            element={
              <PrivateRoute>
                <Aside>
                  <EmdPaidStatusAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sd-paid-status"
            element={
              <PrivateRoute>
                <Aside>
                  <SdPaidStatus />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-sd-paid-status"
            element={
              <PrivateRoute>
                <Aside>
                  <EditSdPaidStatus />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sd-paid-status-add"
            element={
              <PrivateRoute>
                <Aside>
                  <SdPaidStatusAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sd-paid-through"
            element={
              <PrivateRoute>
                <Aside>
                  <SdPaidThrough />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-sd-paid-through"
            element={
              <PrivateRoute>
                <Aside>
                  <EditSdPaidThrough />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sd-paid-through-add"
            element={
              <PrivateRoute>
                <Aside>
                  <SdPaidThroughAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pg-paid-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <PgPaidStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-pg-paid-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditPgPaidStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pg-paid-status-add"
            element={
              <PrivateRoute>
                <Aside>
                  <PgPaidStatusAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pg-paid-through"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <PgPaidThrough />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-pg-paid-through"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditPgPaidThrough />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pg-paid-through-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <PgPaidThroughAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/inspection-agency"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <InspectionAgency />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/inspection-agency-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <InspectionAgencyAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-inspection-agency"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditInspectionAgency />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accepted-material"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <MaterialAccepted />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-accepted-material"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditMaterialAccepted />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/accepted-material-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <MaterialAcceptedAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rejected-material"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <MaterialRejected />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-rejected-material"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditMaterialRejected />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rejected-material-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <MaterialRejectedAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payment-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <PaymentStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-payment-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditPaymentStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payment-status-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <PaymentStatusAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rites-ic-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <RitesIcStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-rites-ic-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditRitesIcStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/rites-ic-status-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <RitesIcStatusAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/download-pdf"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <DownloadPdf />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="department/department-detail/:id"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <DepartmentListDetail />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/unit"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <Unit />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/unit-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <UnitAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-unit"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditUnit />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/project-type"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <ProjectType />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/project-type-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <ProjectTypeAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-project-type"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditProjectType />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/designation"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <Designation />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/designation-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <DesignationAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-designation"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditDesignation />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/work-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <WorkStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/work-status-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <WorkStatusAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-work-status"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditWorkStatus />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/client-category"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <ClientCategory />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/client-category-add"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <ClientCategoryAdd />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-client-category"
            element={
              <PrivateRoute>
                <Aside>
                  {" "}
                  <EditClientcategory />{" "}
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ticket-type"
            element={
              <PrivateRoute>
                <Aside>
                  <TicketType />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/ticket-type-add"
            element={
              <PrivateRoute>
                <Aside>
                  <TicketTypeAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-ticket-type"
            element={
              <PrivateRoute>
                <Aside>
                  <EditTicketType />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/UsersRoles"
            element={
              <PrivateRoute>
                <Aside>
                  <UsersRoles />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/assign-permission/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <AssignPermission />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/permission-list"
            element={
              <PrivateRoute>
                <Aside>
                  <PermissionList />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/features-list"
            element={
              <PrivateRoute>
                <Aside>
                  <FeaturesList />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subdivison"
            element={
              <PrivateRoute>
                <Aside>
                  <SubDivison />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subdivison-add"
            element={
              <PrivateRoute>
                <Aside>
                  <SubDivisonAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-subdivison"
            element={
              <PrivateRoute>
                <Aside>
                  <EditSubDivison />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/purchase-material-costing"
            element={
              <PrivateRoute>
                <Aside>
                  <PurchaseMaterialCosting />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/material-costing-items"
            element={
              <PrivateRoute>
                <Aside>
                  <MaterialCostingItem />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/financial-target"
            element={
              <PrivateRoute>
                <Aside>
                  <FinancialTarget />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/projects/new-project-add"
            element={
              <PrivateRoute>
                <Aside>
                  <NewProjectAdd />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/board-group"
            element={
              <PrivateRoute>
                <Aside>
                  <BoardGroup />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/group-status"
            element={
              <PrivateRoute>
                <Aside>
                  <GroupStatus />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/defect-types"
            element={
              <PrivateRoute>
                <Aside>
                  <DefectTypes />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="projects/edit-project-form/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <EditProjectData></EditProjectData>
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="projects/edit-project-form/lotsLogs/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <ViewLotsLogs></ViewLotsLogs>
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/project-category"
            element={
              <PrivateRoute>
                <Aside>
                  <ProjectCategory />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-project-category"
            element={
              <PrivateRoute>
                <Aside>
                  <AddProjectCategory />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-project-category"
            element={
              <PrivateRoute>
                <Aside>
                  <EditProjectCategory />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="tenders/add-tender"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <TenderAddPage />
                </Aside>
              </TenderPrivateRoute>
            }
          />

          <Route
            exact
            path="/tenders"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <TenderView />
                </Aside>
              </TenderPrivateRoute>
            }
          />
          <Route
            exact
            path="tenders/edit-tender"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <TenderEditPage />
                </Aside>
              </TenderPrivateRoute>
            }
          />
          <Route
            exact
            path="/emd-trash-bin"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <EmdTrashBin />
                </Aside>
              </TenderPrivateRoute>
            }
          />
          <Route
            exact
            path="/roles-and-permission"
            element={
              <PrivateRoute>
                <Aside>
                  <Responsibility />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/manage-roles"
            element={
              <PrivateRoute>
                <Aside>
                  <ManageRole />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/tender-dashboard"
            element={
              <PrivateRoute>
                <Aside>
                  <TenderDashboard></TenderDashboard>
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/tender-cards"
            element={
              <PrivateRoute>
                <Aside>
                  <TenderCards></TenderCards>
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/tender-kanban/"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <TenderKanban></TenderKanban>
                </Aside>
              </TenderPrivateRoute>
            }
          />
          <Route
            exact
            path="/tender-kanban/:id"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <TenderKanban></TenderKanban>
                </Aside>
              </TenderPrivateRoute>
            }
          />

          <Route
            exact
            path="/emd-return-tracker"
            element={
              <TenderPrivateRoute>
                <Aside>
                  <EmdReturnTracker></EmdReturnTracker>
                </Aside>
              </TenderPrivateRoute>
            }
          />

          {/* <Route
            exact
            path="/purchase-dashboard"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <StoreDashboard></StoreDashboard>
                </Aside>
              </PurchasePrivateRoute>
            }
          /> */}

          <Route
            exact
            path="/logistic-list"
            element={
              <Aside>
                <LogisticList />
              </Aside>
            }
          />
          <Route
            exact
            path="/matarial-in"
            element={
              <Aside>
                <MatarialList />
              </Aside>
            }
          />

          <Route
            exact
            path="/addlogistic"
            element={
              <Aside>
                <AddLogisticForm />
              </Aside>
            }
          />
          <Route
            exact
            path="/editlogistic"
            element={
              <Aside>
                <EditLogisticForm />
              </Aside>
            }
          />

          <Route
            exact
            path="/vehicle-request-list"
            element={
              <StorePrivateRoute>
                <Aside>
                  <VehicleRequestListing />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/vehicle-request-list/add-Vehicle-request"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddVechileRequest />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/vehicle-request-list/edit-vehicle-request"
            element={
              <StorePrivateRoute>
                <Aside>
                  <EditVehicleRequest />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/purchase-project-review"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PurchaseProjectReview></PurchaseProjectReview>
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/purchase-project-detail"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PurchaseProjectDetail></PurchaseProjectDetail>
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/vendor-list"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <VendorListPage />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/vendor-list/add-new-vendor"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <AddVendorFormPage />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/vendor-list/edit-vendor"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <EditVendorFormPage />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/main-group-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <MainGroupMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/sub-group-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <SubGroupMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/unit-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <UnitMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/item-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <ItemMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/printout-type-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PrintoutTypeMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/country-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <CountryMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/currency-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <CurrencyMaster />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/state-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <StateMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/add-parameter"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <AddParameterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/godown-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <GodownMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/terms-and-conditions"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <TermsAndConditionsList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/partywise-item-rate"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PartyWiseItemList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/city-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <CityMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/upload-tender-document"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <UploadTenderDocumentList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/partywise-rate-excel-format"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PartywiseRateExcelFormatList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/tender-details"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <TenderDetailsList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/item-details"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <ItemDetailsList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/item-details"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <ItemDetailsList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/approval-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <ApprovalMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/brand-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <BrandMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/material-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <MaterialMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/vendorwise-item"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <VendorwiseItemList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/vechile-master"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <VechileMasterList />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/purchase-order/po-form"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <PurchaseOrder />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/non-tender/purchaseOrder"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <NonTenderPurchaseOrderParent />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/update-non-tender/purchaseOrder"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <UpdateNonTenderPurchaseOrder />
                </Aside>
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="/addItemMaster"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <ItemMaster />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/editItemMaster"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <EditItemMaster />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/warrantyTracker"
            element={
              <PrivateRoute>
                <Aside>
                  <WarrantyTrackerTable />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-warrantyTracker"
            element={
              <PrivateRoute>
                <Aside>
                  <AddWarrantyTracker />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-warrantyTracker"
            element={
              <PrivateRoute>
                <Aside>
                  <EditWarrantyTracker />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/add-coachProduction"
            element={
              <PrivateRoute>
                <Aside>
                  <AddCoachProduction />
                </Aside>
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/edit-coachProduction"
            element={
              <PrivateRoute>
                <Aside>
                  <EditCoachProduction />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/purchase-order"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <POListingPage />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/update-purchase-order"
            element={
              <PurchasePrivateRoute>
                <Aside>
                  <UpdatePurchaseOrder />
                </Aside>
              </PurchasePrivateRoute>
            }
          />
          <Route
            exact
            path="/purchase-order-pdf/:POId"
            element={
              <PurchasePrivateRoute>
                <PurchaseOrderPdf />
              </PurchasePrivateRoute>
            }
          />

          <Route
            exact
            path="projects/projectdetail/add-coachProduction/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <AddCoachProduction />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/matarial-in/material-detail/:id"
            element={
              <PrivateRoute>
                <Aside>
                  <MaterialDetail />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/QMS-List"
            element={
              <StorePrivateRoute>
                <Aside>
                  <QMSList />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/all_store"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AllStore />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/inventorylist/inventoryForm"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddInventoryForm />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/deliveryChallan"
            element={
              <StorePrivateRoute>
                <Aside>
                  <DeliveryChallanList />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/deliveryChallan/edit-delivery-challan"
            element={
              <StorePrivateRoute>
                <Aside>
                  <UpdateDeliveryChallan />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/requestDetail"
            element={
              <StorePrivateRoute>
                <Aside>
                  <RequestDetail />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/accept_vehicle_request/:id"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AcceptVehicleRequest />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/inventoryList"
            element={
              <StorePrivateRoute>
                <Aside>
                  <Inventory />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/itemDetailPage"
            element={
              <StorePrivateRoute>
                <Aside>
                  <InventoryDetailPage />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/listTaxInvoice"
            element={
              <StorePrivateRoute>
                <Aside>
                  <InvoiceList />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/addTaxInvoice"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddInvoice />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/updateTaxInvoice"
            element={
              <StorePrivateRoute>
                <Aside>
                  <UpdateInvoice />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/addCustomerDetail"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddCustomer />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/listCustomerDetail"
            element={
              <StorePrivateRoute>
                <Aside>
                  <CustomerList />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/updateCustomerDetail"
            element={
              <StorePrivateRoute>
                <Aside>
                  <UpdateCustomer />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/kanbanboard/:projectId"
            element={
              <PrivateRoute>
                <Aside>
                  <KanbanBoardProject />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/kanbancreateproject"
            element={
              <PrivateRoute>
                <Aside>
                  <KanbanCreateProject />
                </Aside>
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/GoodReceiptNote"
            element={
              <Aside>
                <GoodReceiptNote />
              </Aside>
            }
          />
          <Route
            exact
            path="/GoodReceiptNote/addGoodReceiptNote"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddGoodsReceipt />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/GoodReceiptNote/edit-Good-Receipt-Note"
            element={
              <StorePrivateRoute>
                <Aside>
                  <EditGoodReceiptNote />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/requisition-list"
            element={
              <StorePrivateRoute>
                <Aside>
                  <RequisitionList />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/requisitionform/addrequisitionform"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddRequisitionForm />
                </Aside>
              </StorePrivateRoute>
            }
          />
          <Route
            exact
            path="/requisitionform/edit-requisition-form"
            element={
              <StorePrivateRoute>
                <Aside>
                  <EditRequisitionForm />
                </Aside>
              </StorePrivateRoute>
            }
          />

          <Route
            exact
            path="/add-deliveryChallan"
            element={
              <StorePrivateRoute>
                <Aside>
                  <AddDeliveryChallan />
                </Aside>
              </StorePrivateRoute>
            }
          />

          {/* HR Dashboard Routes */}

          <Route
            exact
            path="/orgdashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <OrganistaionDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/Timedashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <TimeDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/emp_Directory"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeMainTab />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/add_employee"
            element={
              <HrPrivateRoute>
                <Aside>
                  <AddEmployee />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/employee_detail/:id"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeDetail />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/LeaveDashboard"
            element={
              <HrPrivateRoute>
                <Aside>
                  <LeaveDashboard />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/EmployeeAttendance"
            element={
              <HrPrivateRoute>
                <Aside>
                  <EmployeeAttendance />
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/org-structure"
            element={
              <HrPrivateRoute>
                <Aside>
                  <OrganisationStructure />{" "}
                </Aside>
              </HrPrivateRoute>
            }
          />
          <Route
            exact
            path="/Exits"
            element={
              <HrPrivateRoute>
                <Aside>
                  <ExitsMain />
                </Aside>
              </HrPrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
