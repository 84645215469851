import React from "react";
import "rsuite/DateRangePicker/styles/index.css";
import DateRangePicker from "rsuite/DateRangePicker";

const TenderDatePicker = ({ handleChange }) => {
  function formatDateToLocal(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleDateChange = (value) => {
    if (!value) {
      handleChange("dateFilter", ["", ""]);
      return;
    }
    const formattedStartDate = formatDateToLocal(value[0]);
    const formattedEndDate = formatDateToLocal(value[1]);

    handleChange("dateFilter", [formattedStartDate, formattedEndDate]);
  };

  return (
    <DateRangePicker
      placeholder="Select Date Range"
      style={{
        border: "solid hsl(0, 0%, 80%) 1px",
        borderRadius: "4px",
      }}
      onChange={handleDateChange} // Use the new handler here
    />
  );
};

export default TenderDatePicker;
