import React, { useEffect, useState } from "react";
import CommonTable from "../Common/CommonTable";
import { Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommonTd from "../../Tender/Common/CommonTd";
import { poOrderListApi } from "../purchaseApi";
import { errorsMessage, formatDate } from "../../constant";
import { FaFilePdf } from "react-icons/fa";
import BasicPagination from "../../StoreDepartment/commonComponents/Pagination";

function POListingPage() {
  const [loading, setLoading] = useState(false);
  const [poOrderList, setPoOrderList] = useState([]);
  const userProfile = JSON.parse(localStorage.getItem("profile"));
  const user_id = userProfile?.response?.userDetails?.id;
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState("");
  const { state } = useLocation();
  const [filters, setFilters] = useState({
    startDate: state ? state?.startDate : null,
    endDate: state ? state?.endDate : null,
  });
  const navigate = useNavigate();

  const tableHeading = [
    {
      name: "Sr No",
      width: "2rem",
    },
    {
      name: "Purchase Order No",
      width: "12rem",
    },
    {
      name: "OrderDate",
      width: "8rem",
    },
    {
      name: "Party Name",
      width: "15rem",
    },
    {
      name: "Action",
      width: "4rem",
    },
  ];

  function poOrderData() {
    setLoading(true);
    poOrderListApi({ ...filters, user_id })
      .then((res) => {
        setPoOrderList(res?.data?.response?.PurchaseOrderList?.data);
        setPageCount(res?.data?.response?.PurchaseOrderList?.last_page);
      })
      .catch((err) => {
        errorsMessage(err?.response?.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handlePaginateChange(event, value) {
    setPage(value);
  }

  function editData(element) {
    navigate("/purchase-order/po-form", { state: { editData: element } });
  }
  useEffect(() => {
    poOrderData();
  }, [page]);

  return (
    <div className="page-wrapper">
      <div className="page-content-tab responsive-tab">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-sm-6">
              <h4 className="page-title">Purchase Order</h4>
            </div>

            <div className="col-sm-6 text-end">
              {/* <Link to="/purchase-order/po-form">
                <button className="btn btn-danger">Add New</button>
              </Link> */}
            </div>
          </div>

          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center pb-5"
              style={{ height: "70vh" }}
            >
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
              <Spinner animation="grow" variant="danger" />
            </div>
          ) : (
            <div className="card">
              <div
                className="card-body "
                style={{ padding: "1rem 0", margin: "0" }}
              >
                <div
                  className="table-responsive table-container-scroll"
                  style={{ overflowX: "auto" }}
                >
                  <table
                    className="table common-table"
                    style={{ tableLayout: "unset" }}
                  >
                    <CommonTable tableHeading={tableHeading}></CommonTable>
                    <tbody>
                      {poOrderList?.map((element, index) => (
                        <tr key={index}>
                          <CommonTd width="2rem" value={element.id}></CommonTd>

                          <CommonTd
                            width="12rem"
                            value={element?.purchase_order_no}
                          ></CommonTd>
                          <CommonTd
                            width="8rem"
                            value={formatDate(element?.order_date)}
                          ></CommonTd>
                          <CommonTd
                            width="15rem"
                            value={element?.party_name}
                          ></CommonTd>
                          {/* <CommonTd
                            width="10rem"
                            value={element?.total_qty}
                          ></CommonTd> */}

                          {/* <CommonTd
                            width="8rem"
                            value={element?.sub_total}
                          ></CommonTd> */}

                          <td>
                            <p
                              style={{
                                minWidth: "4rem",
                                maxWidth: "4rem",
                                margin: "auto",
                                padding: "auto",
                                cursor: "pointer",
                              }}
                              className="text-center"
                            >
                              <Link to={`/purchase-order-pdf/${element?.id}`}>
                                <FaFilePdf
                                  className="text-dark font-20 m-0 p-0"
                                  style={{ margin: "auto" }}
                                />
                              </Link>
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="text-end mt-2">
                    <BasicPagination
                      handlePaginateChange={handlePaginateChange}
                      page={page}
                      pageCount={pageCount}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default POListingPage;
